import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './index.css';
//import StudyDetail from './StudyDetail/StudyDetail';
//import ProgramDetail from './ProgramDetail/ProgramDetail';
//import TrialDetail from './TrialDetail/TrialDetail';
//import GermplasmDetail from './GermplasmDetail/GermplasmDetail';
import PlotObservations from './Plot/Plot';
import Header from './Header/Header';
import HowToPage from "./HowToPage/HowToPage";
import AboutPage from "./AboutPage/AboutPage";
import BrapiService from './BrapiService/BrapiService'

// We define the Data Service once here and pass it as a prop in each Route:
const BASE_BRAPI_URL = null
    // The BrapiService instance will pickup the BASE_BRAPI_URL from the env var if we pass in null:
    , brapi = new BrapiService(BASE_BRAPI_URL)
    , techContact = process.env.REACT_APP_TECH_CONTACT || "szarecor@iastate.edu";


ReactDOM.render((
      <Router>
            <div class="wrapper">
                <Header />
                {/*}
                <Route path="/program-detail/:id" render={(props) => <ProgramDetail {...props} brapi={brapi} />} />
                <Route path="/study-detail/:id" render={(props) => <StudyDetail {...props} brapi={brapi} />} />
                <Route path="/trial-detail/:id" render={(props) => <TrialDetail {...props} brapi={brapi} />} />
                <Route path="/germplasm-detail/:id" render={(props) => <GermplasmDetail {...props} brapi={brapi} />} />
                */}
                <Route exact path="/howto" render={() => <HowToPage />} />
                <Route exact path="/about" render={() => <AboutPage />} />
                <Route exact path="/" render={(props) => <PlotObservations {...props} techContact={techContact} brapi={brapi} />} />
            </div>
      </Router>
            )
      , document.getElementById('root')
);

//registerServiceWorker();



