import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

export default class NavBar extends Component {

    render() {
        return <nav>
            <ul>
                <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                <li><NavLink exact to="/howto" activeClassName="active">How To</NavLink></li>
                <li><NavLink exact to="/about" activeClassName="active">About</NavLink></li>
            </ul>
        </nav>
    }

    /*
    render() {
        return <nav>
            <ul>
                <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                <li><NavLink exact to="/observations" activeClassName="active">Home</NavLink></li>
                <li><NavLink exact to="/query" activeClassName="active">Query</NavLink></li>
            </ul>
            <hr />
        </nav>
    }
    */
}
