
export default class uiEventHandlers {

      clearStudies() {
          const _this = this;

            this.setState({

                currentStudies: []
            }
            , function() {
                  _this.parseObservations()

                }
            );
      }


      handleStudyClick(evOrStudyName) {
            const { currentStudies, filteredRecs, recs } = this.state
                  , studyName = (typeof evOrStudyName === 'string') ? evOrStudyName : evOrStudyName.target.value;

            if (currentStudies.includes(studyName)) {
                  // Removing a study
                  const newStudies = currentStudies.filter(study => study !== studyName)
                        , newFilteredRecs = filteredRecs.filter(rec => rec.studyId !== studyName);

                  this.setState(
                        (prevState, props) => {
                            return {
                                currentStudies: newStudies,
                                filteredRecs: newFilteredRecs
                            }
                        }
                  );
            } else {
                  // Adding a study
                  currentStudies.push(studyName)
                  // TODO: Why does this not work on a second click?????

                  this.setState(
                        {currentStudies: currentStudies, isLoading: true}
                        , function() {
                              let study_already_loaded = recs.filter(rec => rec.studyId === studyName);

                              if (study_already_loaded.length === 0) {
                                    this.loadStudyData(studyName);
                              } else {
                                    this.parseObservations();
                              }


                        }
                  );
            }
            // Is this a good place to set obs min/max values?
            this.updateMinAndMaxValues();
      } // handleStudyClick()


      handleEarHeightChange(ev) {

            const key = ev.target.name.indexOf('max') === -1 ? 'earHeightMin' : 'earHeightMax';
            let newState = {};
            newState[key] = parseInt(ev.target.value, 10);
            newState.filterClean = false;

            this.setState(
                  newState
                  , () => this.chainFilters()

            )
      }

      handleGrainMoistureChange(ev) {
            const key = ev.target.name.indexOf('max') === -1 ? 'grainMoistureMin' : 'grainMoistureMax';
            let newState = {};
            newState[key] = parseFloat(ev.target.value);
            newState.filterClean = false;

            this.setState(
                  newState
                  , () => this.chainFilters()
            )
      }


      handlePlantHeightChange(ev) {
            const key = ev.target.name.indexOf('max') === -1 ? 'plantHeightMin' : 'plantHeightMax';
            let newState = {};
            newState[key] = parseInt(ev.target.value, 10);
            newState.filterClean = false;

            this.setState(
                  newState
                  , () => this.chainFilters()
            )
      }

      handleAnthesisDapChange(ev) {
            const key = ev.target.name.indexOf('max') === -1 ? 'anthesisDapMin' : 'anthesisDapMax';
            let newState = {}
                , newVal = parseInt(ev.target.value, 10);
            newState[key] = isNaN(newVal) ? 0 : newVal;

            /*
            if (key === "anthesisDapMin") {
                newState.filterAnthesisMinClean = false;
            } else {
                newState.filterAnthesisMaxClean = false;
            }
            */

            console.log("Anthesis change!", newState)

            this.setState(
                  newState
                  , () => this.chainFilters()
            );
      }

      handleSilkingDapChange(ev) {
            const key = ev.target.name.indexOf('max') === -1 ? 'silkingDapMin' : 'silkingDapMax'
                , newState = {};
            let val = parseInt(ev.target.value, 10);

            if (isNaN(val)) {
                val = (key === "silkingDapMin") ? 1 : 201;
            }

            newState[key] = val;
            newState.filterClean = false;

            this.setState(
                  newState
                  , () => this.chainFilters()
            );
      }


      handleGermplasmChange(ev) {

            const {currentGermplasm, studies} = this.state;
            let newGermplasmList = [];

            if (ev.target.selectedOptions) {
                // This is some retired code around a <select> list version of the UI
                newGermplasmList = Array.prototype.slice.apply(ev.target.selectedOptions).map(optionTag => optionTag.value);
            } else {

                  let row = ev.target;
                  while (row.tagName !== 'TR') row = row.parentElement;
                  const germplasmStr = row.cells[1].textContent;

                  if (currentGermplasm.includes(germplasmStr)) {
                        // Removing Germplasm
                        newGermplasmList = currentGermplasm.filter(gp => gp !== germplasmStr);
                        //newCurrentStudies = studies.filter(study)


                        /*
                        const newStudies = currentStudies.filter(study => study !== studyName)
                          , newFilteredRecs = filteredRecs.filter(rec => rec.studyId !== studyName);

                        this.setState(
                            (prevState, props) => {
                                return {
                                    currentStudies: newStudies,
                                    filteredRecs: newFilteredRecs
                                }
                            }
                        );
                        */

                  } else {
                        // Adding Germplasm
                        newGermplasmList = currentGermplasm.concat([germplasmStr]);
                  }
            }

          const flat = (arr) => {
              if (arr.length === 0) return arr;
              return arr.reduce((a,b) => a.concat(b))
          }

            // Now we need to update the current studies:
          let newStudiesByGermplasm = studies.filter(study => {

              if (study.germplasm) {

                  //const studyGermplasm = study.germplasm.germplasmDbId.map(gp => gp.split("/")).flat()
                  const studyGermplasm = flat(study.germplasm.map(gp => gp.split("/")))
                  const germplasmIntersect = newGermplasmList.filter(gp => studyGermplasm.includes(gp))
                  return germplasmIntersect.length > 0;

              } else {
                  return false;
              }
          });

          newStudiesByGermplasm = Array.from(new Set(newStudiesByGermplasm));

            const newState =  {
                germplasmFilter: newGermplasmList
                , currentGermplasm: newGermplasmList
                , currentGermplasmStudies: newStudiesByGermplasm
            };


            if (newGermplasmList.length === 0) newState.currentStudies = [];

            this.setState(
                 newState
                , () => this.chainFilters()
            );

      }

      handleSort(ev) {

          let thead = ev.target;

          while (thead.tagName !== "THEAD") {
              thead = thead.parentElement;
          }


        const clickedEl = ev.target
            , headers = thead.querySelectorAll("th") //document.querySelectorAll("#observations_table thead th")
            , { sortField, sortDirection } = this.state
            , newSortField = ev.target.getAttribute('data-field') || ev.target.textContent
            , newSortDirection = (sortField === newSortField && sortDirection === 'asc') ? 'desc' : 'asc';


        headers.forEach(header => {
              if (header !== clickedEl) header.classList.remove("sorted", "sorted-asc", "sorted-desc");

        });

        clickedEl.classList.remove("sorted-asc", "sorted-desc");
        clickedEl.classList.add("sorted", `sorted-${newSortDirection}`);


            this.setState(
                  {
                        sortField: newSortField
                        , sortDirection: newSortDirection
                  }
                  , () => this.sort(newSortField, newSortDirection)

            )
      }


};