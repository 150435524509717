import React, { Component } from 'react';
import "./HowToPage.css";

export default class HowToPage extends Component {

    render() {
        return (<React.Fragment>

            <p>To use the demonstration search interface:</p>

            <ol>
                <li>Select one or more <strong>Germplasm</strong> of interest</li>
                <li>Select one or more <strong>Relevant Studies</strong>. For this demonstration, a study is a single field location for a single growing season</li>
                <li>Once the <strong>Observations</strong> data is loaded for the selected <strong>Study(ies)</strong> loaded, The data will be displayed in the <strong>Observations</strong> table</li>
                <li><strong>Observation</strong> data can be sorted by clicking the table headers</li>
                <li><strong>Observation</strong> data can be filtered using the min/max fields in the <strong>Observation Filters</strong> section of the page</li>
            </ol>

            <br /><br /><br />
            <p>Here's a video demo:</p>

            <small>If you have problems playing this video in the Firefox web browser, try following <a href="//support.mozilla.org/en-US/kb/content-blocking?as=u&utm_source=inproduct#w_turn-content-blocking-off-on-individual-sites" target="_blank" rel="noopener noreferrer">these instructions</a> to enable the video.</small>
            <div>
                <iframe id="screencast_embed" title="screencast_embed" src="//drive.google.com/file/d/1CYAAK2Rx65eTMquWQUCEB-3hjeeUWSGT/preview" width="640" height="480"></iframe>
            </div>
        </React.Fragment>);
    }

}
