
if (!Array.prototype.flat) {

      // eslint-disable-next-line
      Array.prototype.flat = function() {
            if (this.length === 0) return this;
            return this.reduce((a, b) => a.concat(b));
      }

}

export default class Utils {

      flat(arr) {

            if (arr.length === 0) return arr;

            return arr.reduce((a,b) => a.concat(b))
      }

      setMinAndMaxObservationValues() {
            // Temporarily short-circuit:
            return;

            const {germplasmFilter, observations} = this.state;
            //const currentGermplasmString = germplasmFilter.join("");
            const observationsFilteredByGermplasm = observations.filter(obs => germplasmFilter.some(currentGp => obs.germplasmName.includes(currentGp)));


            // Don't update the min and max for the various observations if the user has already filtered:
            if (!this.state.filterClean) return;

            function flat(arr) {
                  if (arr.length === 0) return arr;
                  return arr.reduce((a,b) => a.concat(b))
            }

            const allObs = observationsFilteredByGermplasm; //this.state.observations || [];

            if (allObs.length === 0) return;

            //let grainMoistureObs = [];
            //if (allObs) {
            const grainMoistureObs = allObs.filter(ob => ob.observationVariableName === "Grain moisture");
            //}

            let minGrainMoisture = 0;
            let maxGrainMoisture = 100;

            if (grainMoistureObs.length > 0) {
                  minGrainMoisture = grainMoistureObs.reduce((prev, current) => parseFloat(prev.value) < parseFloat(current.value) ? prev : current).value;
                  maxGrainMoisture = grainMoistureObs.reduce((prev, current) => parseFloat(prev.value) > parseFloat(current.value) ? prev : current).value;
            }

            // Reducer functions:
            const getMaxInt = (a,b) => parseInt(a.value, 10) > parseInt(b.value, 10) ? a : b
                  , getMinInt = (a,b) => parseInt(a.value, 10) < parseInt(b.value, 10) ? a : b;

            const anthesisDapObs = allObs.filter(ob => ob.observationVariableName === "Anthesis time")
                  , minAnthesisDap = anthesisDapObs.reduce(getMinInt, 0).value
                  , maxAnthesisDap = anthesisDapObs.reduce(getMaxInt, 100).value;

            const silkingDapObs = allObs.filter(ob => ob.observationVariableName === "Silking time")
                  , minSilkingDap = silkingDapObs.reduce(getMinInt, 0).value
                  , maxSilkingDap = silkingDapObs.reduce(getMaxInt, 100).value;

            // Process Ear Height:
            const earHeightObs = allObs.filter(ob => ob.observationVariableName.toLowerCase().indexOf("ear height") !== -1)
                  , minEarHeight = earHeightObs.reduce(getMinInt, 0).value
                  , maxEarHeight = earHeightObs.reduce(getMaxInt, 200).value;

            // Process Plant Height:
            const plantHeightObs = allObs.filter(ob => ob.observationVariableName.toLowerCase().indexOf("plant height") !== -1)
                  , minPlantHeight = plantHeightObs.reduce(getMinInt, 0).value
                  , maxPlantHeight = plantHeightObs.reduce(getMaxInt, 300).value;

            let allGermplasm = Array.from(new Set(allObs.map(obs => obs.germplasmName)));
            let germplasmParents = allGermplasm.map(germplasm => germplasm.split("/"));

            allGermplasm = allGermplasm.concat(Array.from(new Set(flat(germplasmParents))));
            allGermplasm = Array.from(new Set(allGermplasm)).sort();

            this.setState({

                  germplasm: allGermplasm,
                  anthesisDapMin: minAnthesisDap
                  , anthesisDapMax: maxAnthesisDap
                  , silkingDapMin: minSilkingDap
                  , silkingDapMax: maxSilkingDap
                  , earHeightMin: minEarHeight
                  , earHeightMax: maxEarHeight
                  , plantHeightMin: minPlantHeight
                  , plantHeightMax: maxPlantHeight
                  , grainMoistureMin: minGrainMoisture
                  , grainMoistureMax: maxGrainMoisture
            });
      }
}