/**
 * Created by szarecor on 8/28/18.
 */


export default class ObservationsFilter {


      markFilterStateDirty() {


            const {filterClean} = this.state;

            if (filterClean) {
                this.setState({
                    filterClean: false
                });
            }
      }

      filterGermplasm(recsToFilter) {
            const { germplasmFilter } = this.state;

            if (germplasmFilter.length === 0) return recsToFilter;

            const filteredRecs = recsToFilter.filter(obs => germplasmFilter.some(currentGp => obs.germplasmName.includes(currentGp)))
            return filteredRecs;

      }

      filterGrainMoisture(recsToFilter) {
            const { grainMoistureMin, grainMoistureMax } = this.state;

            let newRecs = recsToFilter.filter(function (rec) {
                        const val = parseFloat(rec['Grain moisture']);
                        return (grainMoistureMin <= val && val <= grainMoistureMax) || isNaN(val);
                  }
                  , this
            );
            this.markFilterStateDirty();
            return newRecs;
      }

      filterPlantHeight(recsToFilter) {
            const { plantHeightMin, plantHeightMax} = this.state;

            let newRecs = recsToFilter.filter(function (rec) {
                        const val = parseInt(rec['Plant height'], 10);
                        return (plantHeightMin <= val && val <= plantHeightMax) || isNaN(val);
                  }
                  , this
            );
            this.markFilterStateDirty();
            return newRecs;
      }


      filterEarHeight(recsToFilter) {
            const { earHeightMin, earHeightMax} = this.state;

            let newRecs = recsToFilter.filter(function (rec) {
                        const val = parseInt(rec['Ear height'], 10);
                        return (earHeightMin <= val && val <= earHeightMax) || isNaN(val);
                  }
                  , this
            );
            this.markFilterStateDirty();
            return newRecs;
      }


      filterAnthesisDap(recsToFilter) {
            const {  anthesisDapMin, anthesisDapMax } = this.state;

            let newRecs = recsToFilter.filter(
                  function(rec) {
                        const val = parseInt(rec["Anthesis time"], 10);
                        return (anthesisDapMin <= val && val <= anthesisDapMax) || isNaN(val);
                  }
                  , this
            );

            this.markFilterStateDirty();
            return newRecs;
      }


      filterSilkingDap(recsToFilter) {
            const {  silkingDapMin, silkingDapMax } = this.state;

            this.markFilterStateDirty();
            return recsToFilter.filter(
                  function(rec) {
                        const val = parseInt(rec["Silking time"], 10);
                        return (silkingDapMin <= val && val <= silkingDapMax) || isNaN(val);
                  }
                  , this
            );
      }


      clearFilters() {

            //const { germplasmFilter } = this.state;

            this.setState({
               filterClean: true
            });

            // Reset the default min and max values:
            this.setMinAndMaxObservationValues();

            // Now we need to refilter the data:
            this.chainFilters();
      }

}