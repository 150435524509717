/**
 * This class is a wrapper around all the server communication between the frontend and BrAPI
 */
class BrapiService {
      console_log = false;

      constructor(BASE_BRAPI_URL) {

            //params = params || {};
            this.pendingRequestCount = 0;
            this.status = 'working';
            //this.BASE_URL = params.base_url || process.env.REACT_APP_BRAPI_SERVER + '/g2f-brapi/v1/';
            this.BASE_URL = BASE_BRAPI_URL || process.env.REACT_APP_BRAPI_SERVER; // + '/g2f-brapi/v1/';
            this.messageHandler = message => {}; //params.messageHandler || function(message) { if (console_log) console.log('MESSAGE FROM DAO:', message); };
            this.loadingObservations = {}
            this.cache = {
                  studyObservations: {}
            }

      }

      getPlotRecords(studyId) {

            this.getObservationsByStudy(studyId)
            //this.fetch('studies/' + studyId + '/observations/?pageSize=' + pageSize + "&page=" + pageNumber).then(function(resp) {
            //this.get

      }

      fetch(partialPath, force) {
            /** To keep the code DRY, this method consolidates all the fetch() calls into a single method.
             * This method returns a promise.
             * */

            const _this = this;
            force = force === true ? true : false;

            force = true; // There is caching weirdness, so disabling cache for now...

            if (!force && this.BASE_URL in this.cache && partialPath in this.cache[this.BASE_URL]) {

                  return new Promise(function(resolve, reject) {
                        resolve(_this.cache[_this.BASE_URL][partialPath]);
                  });

                  //return this.cache[partialPath];
            }

            const URL = this.BASE_URL + partialPath;

            //this.messageHandler('FETCHING ' + URL);

            this.pendingRequestCount++;

            return fetch(URL)
            .then(function(response) {

                _this.pendingRequestCount--;

                  if (!response.ok) {
                        console.log("throwing error...")
                        throw new Error(response.statusText);
                  }
                  return response;
            })
            .then(function(response) {
                  return response.json();
            })
            .then(function(responseData) {

                  // Populate the cache with the fetched data:
                  if (!(_this.BASE_URL in _this.cache)) _this.cache[_this.BASE_URL] = {};
                  _this.cache[_this.BASE_URL][partialPath] = responseData.result;

                  return responseData; //.result;



            }).catch(function(error) {
                  if (console && console.log) {
                        console.log("ERROR FETCHING HTTP RESOURCE:", URL);
                        console.log(error);
                  }
                  _this.pendingRequestCount--;

                  throw error;


            });
      }


      fetchDetail(partialPath, force) {
            return this.fetch(partialPath, force).then(function(resp) {

                  return resp.result;
            })
      }


      fetchList(partialPath, force) {
            return this.fetch(partialPath, force).then(function(resp) {
                  // TODO: better error handling than this
                  if (!resp) return [];
                  if (resp.result && resp.result.data) return resp.result.data;

                  return [];
            }).catch(err => {
                  console.log("Fetch List caught an error")
                  console.log(err);

                  throw err;

            });
      }

      getObservationsByStudy(studyId, pageSize, pageNumber) {

            pageSize = pageSize || 100;
            pageNumber = pageNumber || 1;
            const _this = this;

            if (studyId in this.loadingObservations) {

                  window.setTimeout(
                        function() {

                        }
                        , 500
                  )
            }

            return this.fetch('studies/' + studyId + '/observations/?pageSize=' + pageSize + "&page=" + pageNumber).then(function(resp) {

                  return resp;

            }).then(function(resp) {
                  // Now we want to get a scale for each observation variable

                  // Unfortunately the studies/<studyId>/observations/ endpoint gives us the ObservationVariableName,
                  // but does not give us the related Observation Variable Scale, so we need to make another call:
                  const scaleUrl = 'studies/' + studyId + '/observationvariables/?pageSize=200';

                  return _this.fetch(scaleUrl).then(function(scaleResp) {

                        const obsVariablesResponse = scaleResp.result;

                        // We are going to create a simple mapping of obs var name => unit name:
                        let unitsMap = {}

                        obsVariablesResponse.data.forEach(function(obsVar) {
                              unitsMap[obsVar.name] = obsVar.scale.name;
                        });


                        // Now, we use this mapping to add a units string to each Observation:
                        resp.result.data.forEach(function(r) {
                               r.unitStr = unitsMap[r.observationVariableName];
                        });

                        return resp;
                  });
            });
      }


      loadStudyObservations(studyIds, pageSize, pagesToLoad) {

            this.messageHandler('Loading observations for ' + studyIds)

            //TODO: Make this handle a single studyId again...

            // Populates the cache with all the observation data for a study

            if (typeof(studyIds) === 'string') studyIds = [studyIds];

            pageSize = pageSize || 1000;
            const _this = this;

            //pageSize = 100;
            //pagesToLoad = 1;

            //studyIds.forEach(function(studyId) {

            // TODO: Fix this later to handle more than one study?
            /*
             const studyId = studyIds[0];
            let url = 'studies/' + studyId + '/observations/?pageSize=' + pageSize + '&page='
            this.loadingObservations[studyId] = true;


            for (var i=0; i<pagesToLoad; i++) {



            }*/


            while (studyIds.length > 0) {

                  let studyId = studyIds.pop();

                  if (studyId in this.loadingObservations && this.loadingObservations[studyId] === true) return;

                  this.loadingObservations[studyId] = true;
                  this.cache.studyObservations[studyId] = []

                  return this.fetch('studies/' + studyId + '/observations/?pageSize=' + pageSize + '&page=1').then(function (resp) {

                        _this.cache.studyObservations[studyId] = _this.cache.studyObservations[studyId].concat(resp.result.data);

                        let currentPage = resp.metadata.pagination.currentPage
                              , pageCount = pagesToLoad || resp.metadata.pagination.totalPages
                              , totalObs = pagesToLoad ? pagesToLoad * pageSize : resp.metadata.pagination.totalCount;


                        const addStudyResponse = resp => {
                              _this.cache.studyObservations[studyId] = _this.cache.studyObservations[studyId].concat(resp.result.data);
                              if (currentPage >= pageCount) _this.loadingObservations[studyId] = false;
                        }

                        while (currentPage < pageCount) {
                              currentPage += 1;
                              _this.fetch('studies/' + studyId + '/observations/?pageSize=' + pageSize + '&page=' + currentPage).then(resp => addStudyResponse(resp));
                        }

                        return new Promise((resolve, reject) => {

                              let wait = setInterval(
                                    function () {
                                          if (_this.cache.studyObservations[studyId].length === totalObs && studyIds.length === 0) {
                                                window.clearInterval(wait);
                                                _this.messageHandler("Finished loading observations for " + studyId)
                                                resolve(_this.cache.studyObservations[studyId]);
                                          }
                                    }
                                    , 100
                              );
                        });


                  });

            }
      }



      getCrops() {
            return this.fetchList('crops');
      }


      getPrograms() {
            return this.fetchList('programs');
      }

      getProgram(programId) {
            // Not using this.fetch.. for the time being b/c we need to post a body unlike other methods
            // we've come across thus far.
            const subPath = 'programs'; //-search';
            /*
            const body = {
                  'programDbId': programId,
                  //'name': 'Wheat Resistance Program',
                  //'abbreviation': 'DRP1',
                  //'objective': 'Disease resistance',
                  //'leadPerson': 'Dr. Henry Beachell',
                  //'commonCropName': 'wheat',
                  'pageSize': 1000,
                  'page': 0
            };
            */

            const _this = this;



            return fetch(
                  this.BASE_URL + subPath,
                  {
                        //body: JSON.stringify(body),
                        method: 'GET'
                  })
                  .then(function(response) {
                        return response;
                  })
                  .then(function(resp) {
                        return resp.json();
                  })
                  .then(function(data) {
                        // The above request is not filtered/searching on the programDbId, so (for now) we filter
                        // the response data ourselves.
                        // TODO: figure out if the bad data response is a problem with my request or their server...


                        const programs = data.result.data.filter(function(program) {
                              return parseInt(program.programDbId, 10) === parseInt(programId, 10);
                        });

                        return programs[0] || null;

                  })
                  .then(function(program) {
                        // Now we want to know what trials are associated with the program
                        return _this.getTrials()
                              .then(function(trials) {

                                    // Filter the trials based on the relevant program
                                    const filteredTrials = trials.filter(function(trial) {

                                          return parseInt(trial.programDbId, 10) === parseInt(programId, 10);
                                    });

                                    program.trials = filteredTrials
                                    return program;
                              });
                  });

      }

      getTrial(trialId) {
            const subPath = 'trials/' + trialId;
            return this.fetchDetail(subPath);
      }

      getTrials() {
            return this.fetchList('trials');
      }


      getLocations() {
            return this.fetchList('locations');
      }


      getLocationDetail(locationId) {
            return this.fetchDetail('locations/' + locationId);
      }


      getStudyDetail(studyId) {
            return this.fetchDetail("studies/" + studyId);
      }


      getStudyGermplasm(studyId) {

            //this.messageHandler("Fetching Germplasm for " + studyId);

            const partialPath = ["studies", studyId, "germplasm"].join("/")
            return this.fetchList(partialPath);
      }


      getObservationUnits(studyId) {
            const partialPath = ["studies", studyId, "observationunits/"].join("/")
            return this.fetchList(partialPath);
      }


      getAllStudies() {
            const partialPath = "studies-search?pageSize=3000";
            return this.fetchList(partialPath);
      }


      getGermplasmDetail(germplasmId) {

            germplasmId = parseInt(germplasmId, 10)

            const partialGermplasmPath = "germplasm/" + germplasmId;
            const _this = this;
            // First we are going to get all the studies.
            // Second, we get the germplasm for each study,
            // Finally, we return the germplasm detail along with the relevant study info

            return this.getAllStudies().then(function(studies) {

                  // WE are going to massage the studies into a obj with germplasmDbId (key) => { studyDbId => study... }
                  const studiesByGermplasm = {};

                  if (_this.console_log) console.log('WHAT IS STUDIES?')
                  if (_this.console_log) console.log(studies);
                  if (_this.console_log) console.log("")

                  studies.forEach(function(study) {

                        _this.getStudyGermplasm(study.studyDbId).then(function(studyGermplasm) {

                              studyGermplasm.forEach(function(gp) {
                                    const id = gp.germplasmDbId; //, 10);
                                    if (_this.console_log) console.log("GP", id, id in studiesByGermplasm);

                                    if (!(id in studiesByGermplasm)) studiesByGermplasm[id] = {};

                                    studiesByGermplasm[id][study.studyDbId] = study;
                              });
                        });
                  }); // End of GetAllStudies.then.map...

                  // At this point, we should have a mapping of germplasmDbId => studies for each Germplasm:
                  return _this.fetchDetail(partialGermplasmPath).then(function(germplasmDetail) {

                        if (_this.console_log) console.log('WHAT IS THE STUDIES OBJ?')
                        if (_this.console_log) console.log(studiesByGermplasm);
                        if (_this.console_log) console.log(germplasmId in studiesByGermplasm)

                        germplasmDetail.studies = germplasmId in studiesByGermplasm ? Object.values(studiesByGermplasm[germplasmId]) : [];
                        return germplasmDetail;
                  });
            });

      }


      getGermplasm() {

            this.messageHandler("Fetching Germplasm...")
            // pageSize was 3000...
            return this.fetchList('germplasm-search?pageSize=100');

      }
}

export default BrapiService;