import React, { Component } from 'react';
import './GermplasmComboBox.css';

export default class GermplasmComboBox extends Component {

      constructor(props) {
            super(props);
            this.changeHandler = this.changeHandler.bind(this);
            this.removeHandler = this.removeHandler.bind(this);
      }

      changeHandler(ev) {
            this.props.onChange(ev)
      }

      removeHandler(ev) {
            this.props.onRemove(ev)
      }

      render() {

            const {options, currentOptions } = this.props;

            return (<div id="germplasm_block">
                <h3>Germplasm</h3>
                <table id="germplasm_table">
                  <tbody>
                  { options.map(option => <tr onClick={this.changeHandler} key={option}>
                      <td><input readOnly={true} type="checkbox" checked={currentOptions.includes(option)} /></td>
                      <td className={currentOptions.includes(option) ? 'current-germplasm' : ''}>{option}</td>
                  </tr>)}

                  </tbody>
            </table></div>)


/*
            return (<div>
                  <select multiple onChange={this.changeHandler}>
                        { options.map(option => <option key={option} value={option} selected={currentOptions.includes(option)}>{option}</option>)}
                  </select>
                  <br />
                  <div>
                  {currentOptions.map(option => <button key={option} className="combo-box-selected-option-button" onClick={_this.removeHandler} type="button" value={option}>{option}</button>) }
                  </div>

            </div>)
          */

      }




}