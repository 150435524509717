import React, { Component } from 'react';
import './StudiesControl.css';
import GermplasmComboBox from '../GermplasmComboBox/GermplasmComboBox.js';

export default class StudiesControl extends Component {


      constructor(props) {
            super(props);

            //const allGermplasm = this.props.germplasm.map(gplasm => gplasm.split("/"))

            this.state = {
                  groupByOptions: ['trial', 'location', 'germplasm']
                  , currentGroupBy: 'germplasm'
            }

            this.renderByLocation = this.renderByLocation.bind(this);
            this.renderByTrial = this.renderByTrial.bind(this);
            this.renderByGermplasm = this.renderByGermplasm.bind(this);
            this.handleRowClick = this.handleRowClick.bind(this);
            this.comboxBoxChangeHandler = this.comboxBoxChangeHandler.bind(this);
            this.comboBoxRemoveHandler = this.comboBoxRemoveHandler.bind(this);

            this.checkAllStudies = this.checkAllStudies.bind(this);
      }

      componentDidMount() {
            //this.initTableLayout();
      }

      componentDidUpdate() {
          //this.initTableLayout();


      }


      initTableLayout() {
          // Because there is no good pure css implementation of sticky headers, we are going
          // to make the thead and tbody elements display as divs and abs position the header.
          // For this to work, we need to manually make sure the thead and tbody of the same column
          // widths, we do that below by reading the computed column widths and then adding stylesheet
          // rules enforcing those widths before we change the thead and tbody to divs.


          let bodyCols = Array.prototype.slice.call(document.querySelectorAll("table#studies_table tbody tr:first-child td"))
              , headerCols = Array.prototype.slice.call(document.querySelectorAll("table#studies_table thead tr:last-child th"))
              , styleEl = document.body.appendChild(document.createElement("style"))
              , styleSheet = styleEl.sheet;

          styleSheet.insertRule("table#studies_table thead, table#studies_table tbody { display: block; }");

          bodyCols.forEach(function(col, i) {

              //let style = window.getComputedStyle(col)
              let colNum = i+1

                  // If you have header content width than the corresponding content data, we need to know about that
                  // and use the width width from the header or the column widths will jog once the header is set to
                  // display block in order to fix its position:
                  , width1 = Math.round(parseFloat(window.getComputedStyle(col).width))
                  , width2 = Math.round(parseFloat(window.getComputedStyle(headerCols[i]).width))
                  , maxWidth = Math.max(width1, width2);

              styleSheet.insertRule("table#studies_table tr td:nth-child(" + colNum + "), table#studies_table thead tr:last-child th:nth-child(" + colNum + ") { width: " + maxWidth + "px;}" );
          });

          // However, fixed headers aren't really necessary unless there is a significant number of rows, so let's check that first:
          let rows = document.querySelector("table#studies_table tbody").rows;
              //, firstBodyRow = rows[0]
              //, colHeaders = document.querySelectorAll("table#studies_table thead tr:last-child th");

          if (rows.length >= 10) {

              let headerStyle = window.getComputedStyle(document.querySelector("table#studies_table thead"))
                  , headerHeight = parseInt(headerStyle.height, 10);

              styleSheet.insertRule("table#studies_table thead, table tbody { display: block; }");
              //styleSheet.insertRule("table thead{ top: 0; }");

              styleSheet.insertRule("table#studies_table tbody { z-index: 10; position: relative; top: " + headerHeight + "px; }");
              /* The following issue magically disappeared, but I am leaving the comment here in case it reappears: */
              /* Adding position: fixed creates a ~12px padding/margin between top of header and the wrapper element, why? */
              styleSheet.insertRule("table#studies_table thead { position: fixed; z-index:20; background-color: #fff;}");
          }

      }




      flat(arr) {
            if (arr.length === 0) return arr;
            return arr.reduce((a,b) => a.concat(b))
      }


      checkAllStudies(ev) {
            let tableEl = ev.target;
            while (tableEl.tagName !== 'TABLE') { tableEl = tableEl.parentElement; }

            const {handlers} = this.props
                , isChecked = ev.target.checked, cbs = tableEl.querySelectorAll('tbody tr'); // td:first-child input[type=checkbox]');

            Array.prototype.slice.apply(cbs).forEach((cb,i) => {
                cb.querySelector("td input[type=checkbox]").checked = isChecked;
                if (isChecked) handlers.handleStudyClick(cb.cells[1].textContent);
            });

            if (!isChecked) handlers.clearStudies();





      }

      renderByGermplasm() {

            const _this = this;

            const { germplasm, currentGermplasm, currentGermplasmStudies, currentStudies, handlers} = this.props

                  const allGermplasm = Array.from(new Set(_this.flat(germplasm.map(gplasm => gplasm.germplasmName.split("/"))))).sort()
            //const currentGermplasm = this.props.currentGermplasm

            const selectHandler = this.comboxBoxChangeHandler
                  , removeHandler = this.comboBoxRemoveHandler;

            //let studiesByGermplasm = currentStudies;

            //console.log("filtering studiesByGermplasm", currentStudies, currentGermplasm);
            /*
            let studiesByGermplasm = studies.filter(function(study) {

                  if (study.germplasm) {

                        //const studyGermplasm = study.germplasm.germplasmDbId.map(gp => gp.split("/")).flat()
                        const studyGermplasm = _this.flat(study.germplasm.map(gp => gp.split("/")))
                        const germplasmIntersect = currentGermplasm.filter(gp => studyGermplasm.includes(gp))
                        return germplasmIntersect.length > 0;

                  } else {
                        return false;
                  }
            });

            studiesByGermplasm = Array.from(new Set(studiesByGermplasm));

            // We want to find the intersection of the user-selected germplasm and the germplasm for the study at hand:
            // It's not a one-to-one comparison as each item in currentGermplasm might be a substring of each study.germplasm entry
            studiesByGermplasm.forEach(function(study) {

                  study.germplasmIntersection = Array.from(new Set(study.germplasm.filter(function(gp) {

                        for (var i=0,l=currentGermplasm.length; i<l; i++) {
                              if (gp.indexOf(currentGermplasm[i]) !== -1) return true;
                        }
                        return false;
                  })));
            });
            */

            return (
                  <div>
                        <GermplasmComboBox currentOptions={currentGermplasm}
                               selected
                               options={allGermplasm}
                               onRemove={removeHandler}
                               onChange={selectHandler}
                               handlers={handlers} />


                      <div id="studies_block">
                      <h3>Relevant Studies</h3>
                      <div className="table_wrapper">
                        <table id="studies_table">
                              <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        {/*<th><input type='checkbox' onClick={this.checkAllStudies} /></th>*/}
                                        <th>Study</th>
                                        <th>Location</th>
                                        <th>Relevant Germplasm</th>
                                    </tr>
                              </thead>
                              <tbody>{ currentGermplasmStudies.length === 0 ? <tr><td colSpan="4" style={{textAlign:'center'}}>No Germplasm Selected</td></tr> : null}


                              {
                                    currentGermplasmStudies.map(function(study) {

                                        // First filter the Study's germplasm Array down to only what is relevant to the
                                        // currently selected germplasm state
                                        const germplasmStrs = study.germplasm.filter((gp, indx) => {

                                            let isMatch = false;

                                            for (let i=0,l=currentGermplasm.length; i<l; i++) {
                                                const currentGp = currentGermplasm[i];
                                                if (gp.indexOf(currentGp) > -1) {
                                                    isMatch = true;
                                                    break;
                                                }
                                            }
                                            return isMatch;
                                        });

                                        const germplasmStrUnique = Array.from(new Set(germplasmStrs));

                                        // Now format the filtered Array to highlight the relevant selection substring:
                                        const formattedGermplasmStrs = germplasmStrUnique.map(gp => {
                                            let _gpStr = gp;

                                            currentGermplasm.forEach(currentGp => {
                                                if (_gpStr.includes(currentGp)) {
                                                    _gpStr = _gpStr.replace(currentGp, `<strong>${currentGp}</strong>`);
                                                }
                                            });
                                            return _gpStr;
                                        });

                                        const formattedGermplasm = formattedGermplasmStrs.join(", ")
                                        , isCurrentStudy = currentStudies.includes(study.studyDbId);

                                          return <tr key={study.studyDbId} className={isCurrentStudy ? "current-study" : ""}>

                                                <td><input type="checkbox" onClick={() => handlers.handleStudyClick(study.studyDbId)} /></td>

                                                <td onClick={() => handlers.handleStudyClick(study.studyDbId)}>{study.studyDbId}</td>

                                                <td>{ study.location.additionalInfo.city ? study.location.additionalInfo.city + "," + study.location.additionalInfo.state_or_province : "" }
                                                &nbsp; &nbsp;
                                                <small>{study.location.latitude ? "(" + study.location.latitude + "/" : ""}
                                                {study.location.longitude ? study.location.longitude + ")" : ""}</small>
                                                </td>

                                                <td dangerouslySetInnerHTML={{ __html: formattedGermplasm }}></td>
                                          </tr>
                                    })
                                    }

                              </tbody>
                        </table>
                      </div>
                      </div>
                        <br />
                  </div>
            )
      }

      comboxBoxChangeHandler(ev) {
            this.props.handlers.handleGermplasmChange(ev);

      }

      comboBoxRemoveHandler(ev) {
            this.props.handlers.removeCurrentGermplasm(ev.target.value);

      }

      renderByLocation() {
            //const { currentGroupBy } = this.state;
            let { studies } = this.props;


            const sorter = (a,b) => {
                  let aCity = a.location.additionalInfo.city
                        , bCity = b.location.additionalInfo.city;

                  aCity = aCity === undefined ? "" : aCity.toLowerCase();
                  bCity = bCity === undefined ? "" : bCity.toLowerCase();

                  if (aCity === bCity) return 0;
                  return aCity < bCity ? -1 : 1;
            }

            studies.sort(sorter);

            return (<div><p>Rendering by Location</p>
                  <table className="studies-table">
                        <tbody>
                        { studies.map(function(study) {
                              return (<tr key={study.studyDbId}>
                                    <td>{study.studyDbId}</td>
                                    <td>{ study.locationDbId }</td>
                                    <td>{ study.location.additionalInfo.city }</td>
                                    <td>{ study.location.additionalInfo.state_or_province }</td>
                              </tr>)
                        })}
                        </tbody>
                  </table>
            </div>)
      }


      handleRowClick(ev) {
            let rowEl = ev.target.parentElement;
            this.props.handlers.handleStudyClick(rowEl.cells[0].textContent);
      }


      renderByTrial() {

            let { studies, currentStudies } = this.props
                  , trials = {}
                  , _this = this;

            // Here we take an array of Studies and massage it into a new Object that contains an array of Studies per Trial
            // with Trial.name as the Object keys pointing to the arrays...
            studies.forEach(function(study) {
                  if (!(Object.keys(trials).includes(study.trialName))) {
                        trials[study.trialName] = [];
                  }
                  trials[study.trialName].push(study)
            });

            return (<table className="studies-table">
                        { Object.keys(trials).map(function(trialName) {
                              const studies = trials[trialName]

                              return (
                                    <tbody key={trialName + "_tbody"}>
                                    <tr className="trial-name-row" key={trialName}>
                                          <td colSpan="4" className="trial-header-cell">{ trialName }</td>
                                    </tr>

                                    { studies.map(function(study) {

                                          const classStr = currentStudies.includes(study.studyDbId) ? "selected-study" : "";

                                          return <tr key={study.studyDbId} onClick={_this.handleRowClick} className={classStr}>
                                                <td>{study.studyDbId }</td>
                                                <td>{study.location.additionalInfo.city}</td>
                                                <td>{study.location.additionalInfo.state_or_province}</td>
                                                <td>{study.location.countryCode}</td>
                                          </tr>
                                    })}
                                    </tbody>
                              );
                        })

                        }
                  </table>)
      }


      renderSortSelector() {
            const { groupByOptions, currentGroupBy} = this.state;
            const _this = this;

            const updateGroupByState = newState => { _this.setState({currentGroupBy: newState}); };

            return (<div>
                <h4>Explore By:</h4>
                  { groupByOptions.map(function(option) {
                        const classStr = (option === currentGroupBy) ? "selected group-by-button" : "group-by-button";
                        return <button className={classStr} onClick={() => updateGroupByState(option) } key={option} type="button" value={option}>{option}</button>
                  })}
            </div>)
      }


      render() {

            //const { currentStudies } = this.props;
            const {currentGroupBy} = this.state;
            const renderer = currentGroupBy === 'location' ? this.renderByLocation : (currentGroupBy === 'germplasm' ? this.renderByGermplasm : this.renderByTrial);

            return (<div>
                        { /*this.renderSortSelector()*/ }
                        { renderer() }
            </div>)




      }

}
