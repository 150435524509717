import React, { Component } from 'react';
import './ObservationRenderer.css';

export default class ObservationRenderer extends Component {


    render() {
            const { handlers, filteredRecs, filterClean } = this.props;
            const {anthesisDapMin, anthesisDapMax, silkingDapMin, silkingDapMax,
                earHeightMin, earHeightMax, plantHeightMin, plantHeightMax, grainMoistureMin, grainMoistureMax} = this.props;

            return (
                  <div id="observations_block">
                    <div className="obs-filters-wrapper">
                        <h3>Observation Filters</h3>
                        <table id="observation_filters_table">
                            <thead>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Minimum</td>
                                    <td>Maximum</td>
                                    <td>&nbsp;</td>
                                    <td>Minimum</td>
                                    <td>Maximum</td>
                                    <td>&nbsp;</td>
                                    <td>Minimum</td>
                                    <td>Maximum</td>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Anthesis Days</td>
                                <td><input type="number" name="anthesis_dap_min" step="1" value={anthesisDapMin} onChange={handlers.handleAnthesisDapChange} /></td>
                                <td><input type="number" name="anthesis_dap_max" step="1" value={anthesisDapMax} onChange={handlers.handleAnthesisDapChange} /></td>

                                <td>Silking Days</td>
                                <td><input type="number" name="silking_dap_min" value={silkingDapMin} onChange={handlers.handleSilkingDapChange} /></td>
                                <td><input type="number" name="silking_dap_max" value={silkingDapMax} onChange={handlers.handleSilkingDapChange} /></td>

                                <td>Grain Moisture</td>
                                <td><input type="number" step=".1" name="grain_moisture_min" value={grainMoistureMin} onChange={handlers.handleGrainMoistureChange} /></td>
                                <td><input type="number" step=".1" name="grain_moisture_max" value={grainMoistureMax} onChange={handlers.handleGrainMoistureChange} /></td>
                            </tr>
                            <tr>
                                <td>Ear Height</td>
                                <td><input type="number" name="ear_height_min" value={earHeightMin} onChange={handlers.handleEarHeightChange} /></td>
                                <td><input type="number" name="ear_height_max" value={earHeightMax} onChange={handlers.handleEarHeightChange} /></td>

                                <td>Plant Height</td>
                                <td><input type="number" name="plant_height_min" value={plantHeightMin} onChange={handlers.handlePlantHeightChange} /></td>
                                <td><input type="number" name="plant_height_max" value={plantHeightMax} onChange={handlers.handlePlantHeightChange} /></td>

                                <td colSpan="3" style={{textAlign:'right'}}><button type="button" onClick={handlers.clearFilters}>Reset</button></td>
                            </tr>
                            </tbody>
                        </table>
                        <br />

                  </div>

                      <h3>Observations</h3>

                        <table id="observations_table">
                              <thead>

                              <tr>
                                    <th rowSpan="2" className="sortable" onClick={handlers.handleSortClick}>City</th>
                                    <th rowSpan="2" className="sortable" onClick={handlers.handleSortClick}>St/Pr</th>
                                    <th rowSpan="2">Study ID</th>

                                    <th colSpan="3">Observation Unit</th>

                                    <th rowSpan="2" className="sortable" data-field="germplasmName" onClick={handlers.handleSortClick}>Germplasm</th>
                                    <th rowSpan="2" title="Click to sort" className="sortable" data-field="Anthesis time" onClick={handlers.handleSortClick}>Anthesis Days</th>
                                    <th rowSpan="2" title="Click to sort" className="sortable" data-field="Silking time" onClick={handlers.handleSortClick}>Silking Days</th>
                                    <th rowSpan="2" title="Click to sort" className="sortable" onClick={handlers.handleSortClick}>Ear height (cms)</th>
                                    <th rowSpan="2" title="Click to sort" className="sortable" onClick={handlers.handleSortClick}>Plant height (cms)</th>
                                    <th rowSpan="2" title="Click to sort" className="sortable" onClick={handlers.handleSortClick}>Grain moisture (percentage)</th>
                                    <th rowSpan="2" className="sortable" onClick={handlers.handleSortClick}>Date</th>
                              </tr>
                              <tr>
                                  <th>Type</th>
                                  <th>ID</th>
                                  <th>Name</th>
                              </tr>

                              </thead>
                              <tbody>
                              <tr style={{display:filteredRecs.length===0 ? 'row' : 'none'}}><td colSpan='13' style={{textAlign:'center'}}>No observations loaded</td></tr>
                              {

                                    filteredRecs.map(function(rec) {
                                        const dateParser = (dateTimeStr) => {
                                                const _tmp = dateTimeStr.split("T")[0].split("-")
                                                let _date = new Date(_tmp.join("/"));
                                                return _date.toLocaleDateString();
                                            }
                                            , _timeStr = dateParser(rec['observationTimestamp'])
                                            , _key = [rec.observationUnitDbId, rec.studyId].join("_");

                                        return <tr key={_key }>
                                                <td>{ rec.city }</td>
                                                <td>{ rec.state }</td>
                                                <td>{ rec.studyId }</td>
                                                <td>{ rec.observationLevel }</td>
                                                <td>{ rec.observationUnitDbId }</td>
                                                <td>{ rec.observationUnitName }</td>
                                                <td>{ rec.germplasmName }</td>

                                                <td>{ rec['Anthesis time'] }</td>
                                                <td>{ rec['Silking time'] }</td>
                                                <td>{ rec['Ear height'] }</td>
                                                <td>{ rec['Plant height'] }</td>
                                                <td>{ rec['Grain moisture'] }</td>
                                                <td>{ _timeStr }</td>
                                          </tr>
                                        })
                              }
                              </tbody>
                        </table>
                  </div>
            ); // return
      }

}
