/**
 * Created by szarecor on 6/25/18.
 */

import React, { Component } from 'react';
//import BrapiService from '../BrapiService/BrapiService.js';
import ObservationRenderer from '../ObservationRenderer/ObservationRenderer.js';
import uiEventHandlers from '../UiEventHandlers/UiEventHandlers.js';
import ObservationsFilter from '../ObservationsFilter/ObservationsFilter.js';
import StudiesControl from '../StudiesControl/StudiesControl.js'
import Utils from '../Utils/Utils.js';
import Messages from '../Messages/Messages.js';
import './Plot.css';
import ExplanationText from "../ExplanationText/ExplanationText";


class PlotObservations extends  Component {
      constructor(props) {
            super(props);

            this.state = {
                observationsMessage: props.match.params.message
                , anthesisDapMin: 0
                , anthesisDapMax: 200
                , silkingDapMin: 0
                , silkingDapMax: 200
                , earHeightMin: 0
                , earHeightMax: 500
                , plantHeightMin: 0
                , plantHeightMax: 1000
                , grainMoistureMin: 0
                , grainMoistureMax: 100
                , studies: []
                , currentGermplasmStudies: []
                , currentStudies: []
                , locations: []
                , germplasm: []
                , currentGermplasm: []
                , germplasmFilter: []
                , allGermplasm: []
                , recs: []
                , filteredRecs: []
                , observations: []
                , sortField: null
                , sortDirection: null
                , isLoading: true
                , messages: []
                // To track if the user has interacted with the filter UI:
                , filterClean: true
                , filterAnthesisMinClean: true
                , filterAnthesisMaxClean: true
                , filterEarHeightMinClean: true
                , filterEarHeightMaxClean: true
                , filteredRecsByStudyAndGermplasm: []

                , techContact: props.techContact
            };

            this.handlers = new uiEventHandlers();

            this.chainFilters = this.chainFilters.bind(this);
            this.loadStudyData = this.loadStudyData.bind(this);


            this.handleStudyClick = this.handlers.handleStudyClick.bind(this);
            this.clearStudies = this.handlers.clearStudies.bind(this);
            this.handleEarHeightChange = this.handlers.handleEarHeightChange.bind(this);
            this.handlePlantHeightChange = this.handlers.handlePlantHeightChange.bind(this);
            this.handleGrainMoistureChange = this.handlers.handleGrainMoistureChange.bind(this);
            this.handleAnthesisDapChange = this.handlers.handleAnthesisDapChange.bind(this);
            this.handleSilkingDapChange = this.handlers.handleSilkingDapChange.bind(this);
            this.handleGermplasmChange = this.handlers.handleGermplasmChange.bind(this);
            this.handleSort = this.handlers.handleSort.bind(this);


            const filters = new ObservationsFilter();
            this.filterGermplasm = filters.filterGermplasm.bind(this);
            this.filterGrainMoisture = filters.filterGrainMoisture.bind(this);
            this.filterPlantHeight = filters.filterPlantHeight.bind(this);
            this.filterEarHeight = filters.filterEarHeight.bind(this);
            this.filterAnthesisDap = filters.filterAnthesisDap.bind(this);
            this.filterSilkingDap = filters.filterSilkingDap.bind(this);
            this.clearFilters = filters.clearFilters.bind(this);
            this.markFilterStateDirty = filters.markFilterStateDirty.bind(this);

            const utils = new Utils();
            this.sort = this.sort.bind(this);
            this.setMinAndMaxObservationValues = utils.setMinAndMaxObservationValues.bind(this);
            this.removeCurrentGermplasm = this.removeCurrentGermplasm.bind(this);

            this.addMessage = this.addMessage.bind(this);
            this.putMessage = this.putMessage.bind(this);

            this.brapi = props.brapi; //new BrapiService();
            /*
            {
                messageHandler: this.putMessage
            }); // props.dao;
            */
            const _this = this;

            this.brapi.getGermplasm().then(function (germplasm) {

                _this.setState({
                    allGermplasm: germplasm
                    , germplasm: germplasm.map(gp => gp.germplasmName)
                    //, messages: this.addMessage("Loaded " + germplasm.length + " germplasm")
                    , messages: ["Initializing", "Loading Germplasm"]
                });
          }).catch(err => {

                this.putMessage(`Oops!, Could not load Germplasm from the API. Please contact <a href="mailto:${this.props.techContact}">${this.props.techContact}</a>`);
                this.isLoading = false;

            });


          this.brapi.getLocations().then(function(locations) {

              _this.brapi.getAllStudies().then(function(studies) {
                  const getStudyLocation = (study) => locations.filter(location => location.locationDbId === study.locationDbId)[0];
                  studies.map(study => study.location = getStudyLocation(study));
                  let count = 0;

                  studies.forEach(function(study, studyIndex) {

                      _this.brapi.getStudyGermplasm(study.studyDbId).then(function(germplasmArray) {
                          count++;
                          study.germplasm = germplasmArray.map(gp => gp.germplasmName);
                          const msg = `Loading Study Germplasm: ${count} of ${studies.length}: ${study.studyDbId}`;
                          _this.putMessage(msg);

                          if (count === studies.length) {

                              _this.clearMessages();
                              _this.putMessage(`Germplasm loaded for ${studies.length} studies`);

                              _this.setState({
                                  isLoading: false
                                  , studies: studies
                                  //, messages: messages
                              });
                          }
                      });
                  });

                  _this.setState({
                          locations: locations,
                      }
                      , _this.chainFilters
                  );
              })
          });

      }

      componentWillMount() {
          /*
          return;
          // Can we safely kill the following code?
            const { germplasm } = this.state
                , _this = this;

            if (germplasm.length === 0) {
                  this.brapi.getGermplasm().then(function (germplasm) {
                        //this.addMessage("Loaded " + germplasm.length + " germplasm")

                        _this.setState({
                              allGermplasm: germplasm
                              , germplasm: germplasm.map(gp => gp.germplasmName)
                              //, messages: this.addMessage("Loaded " + germplasm.length + " germplasm")
                              , messages: ["Initializing", "Loading Germplasm"]
                        })
                  });
            }

            this.brapi.getLocations().then(function(locations) {

                  _this.brapi.getAllStudies().then(function(studies) {
                        const getStudyLocation = (study) => locations.filter(location => location.locationDbId === study.locationDbId)[0];
                        studies.map(study => study.location = getStudyLocation(study));
                        let count = 0;

                        studies.forEach(function(study, studyIndex) {

                              _this.brapi.getStudyGermplasm(study.studyDbId).then(function(germplasmArray) {
                                    count++;
                                    study.germplasm = germplasmArray.map(gp => gp.germplasmName);
                                    const msg = `Loading Study Germplasm: ${count} of ${studies.length}: ${study.studyDbId}`;
                                    const messages = _this.addMessage(msg);

                                    if (count === studies.length) {
                                          _this.setState({
                                              isLoading: false
                                              , studies: studies
                                              , messages: messages
                                          });
                                    }
                              });
                        });

                        _this.setState({
                                    locations: locations,
                              }
                              , _this.chainFilters
                        );
                  })
            });
            */
      }


      clearMessages() {
          this.setState({
             messages: []
          });

      }

      putMessage(message) {
            // TODO: Why are putMessage and addMessage different methods? Should they be combined?
            const messages = this.addMessage(message);
            this.setState({
                  messages: messages
            });
      }

      addMessage(message) {
            /* Takes a message and adds it to this.state.messages and slices the array if the MAX_MESSAGE_COUNT
            theshold has been crossed.

            Returns a new array
            */

            const {messages} = this.state
                , MAX_MESSAGE_COUNT = 5;
            let newMessages = messages.concat(message);

            if (newMessages.length > MAX_MESSAGE_COUNT) {
                newMessages = newMessages.slice(1);
            }
            return newMessages;
      }


      updateMinAndMaxValues() {

          return;
          /*
          const {filteredRecsByStudyAndGermplasm} = this.state;

          if (filteredRecsByStudyAndGermplasm.length === 0) return;

          const anthesisTimes = filteredRecsByStudyAndGermplasm.map(obs => parseFloat(obs["Anthesis time"]) || 0)
              , earHeights = filteredRecsByStudyAndGermplasm.map(obs => parseFloat(obs["Ear height"]) || 0)
              , silkingTimes = filteredRecsByStudyAndGermplasm.map(obs => parseFloat(obs["Silking time"]) || 0)
              , plantHeights = filteredRecsByStudyAndGermplasm.map(obs => parseFloat(obs["Plant height"]) || 0)
              , grainMoistures = filteredRecsByStudyAndGermplasm.map(obs => parseFloat(obs["Grain moisture"]) || 0)

          let anthesisMin = Math.min(...anthesisTimes)
              , anthesisMax = Math.max(...anthesisTimes)
              , earHeightMin = Math.min(...earHeights)
              , earHeightMax = Math.max(...earHeights)
              , silkingDaysMin = Math.min(...silkingTimes)
              , silkingDaysMax = Math.max(...silkingTimes)
              , plantHeightMin = Math.min(...plantHeights)
              , plantHeightMax = Math.max(...plantHeights)
              , grainMoistureMin = Math.min(...grainMoistures)
              , grainMoistureMax = Math.max(...grainMoistures);

          if (!isFinite(anthesisMin)) anthesisMin = 0;
          if (!isFinite(anthesisMax)) anthesisMax = 200;
          if (!isFinite(earHeightMin)) earHeightMin = 0;
          if (!isFinite(earHeightMax)) earHeightMax = 500;
          if (!isFinite(silkingDaysMin)) silkingDaysMin = 0;
          if (!isFinite(silkingDaysMax)) silkingDaysMax = 365;
          if (!isFinite(plantHeightMin)) plantHeightMin = 0;
          if (!isFinite(plantHeightMax)) plantHeightMax = 500;
          if (!isFinite(grainMoistureMin)) grainMoistureMin = 0;
          if (!isFinite(grainMoistureMax)) grainMoistureMax = 100;

          const newState = {
              anthesisDapMin: anthesisMin,
              anthesisDapMax: anthesisMax,
              earHeightMin: earHeightMin,
              earHeightMax: earHeightMax,
              silkingDapMin: silkingDaysMin,
              silkingDapMax: silkingDaysMax,
              plantHeightMin: plantHeightMin,
              plantHeightMax: plantHeightMax,
              grainMoistureMin: grainMoistureMin,
              grainMoistureMax: grainMoistureMax
          };

          this.setState(
              newState
              // This is a kludge!:
              , () => { this.chainFilters() }
          );

           */

      }


      chainFilters() {
            var { recs, currentStudies } = this.state;

            let filteredRecs = recs.filter(rec => currentStudies.includes(rec.studyId))
            filteredRecs = this.filterGermplasm(filteredRecs);

            let newState = {
                    filteredRecsByStudyAndGermplasm: filteredRecs
            };

            // TODO: chaining:
            if (filteredRecs.length > 0) {
                filteredRecs = this.filterEarHeight(filteredRecs);
                filteredRecs = this.filterPlantHeight(filteredRecs);
                filteredRecs = this.filterAnthesisDap(filteredRecs);
                filteredRecs = this.filterGrainMoisture(filteredRecs);
            }

            newState.filteredRecs = filteredRecs;

            this.setState(newState);

            const studyCount = currentStudies.length
                , singleOrPluralStudy = studyCount === 1 ? "study" : "studies";

            if (studyCount > 0) this.putMessage(`showing ${filteredRecs.length} records for ${currentStudies.length} ${singleOrPluralStudy}`);
      }

      loadStudyData(studyId) {

            const OBSERVATION_PAGE_SIZE = 1000
                , _this = this
                , { locations } = this.state;


            this.setState({messages:[]});
            this.putMessage(`Loading observations for ${studyId}`);

            // We want to add location data to each record (obs joined by study/plot/etc), so fetch the location data:
            if (locations.length === 0) {
                  this.brapi.getLocations().then(function (newLocations) {
                        _this.setState({
                              locations: newLocations
                        });
                  });
            }

            _this.brapi.loadStudyObservations(studyId, OBSERVATION_PAGE_SIZE).then(function(resp) {
                _this.putMessage(`Finished loading observations for ${studyId}`);

                _this.setState(
                              (prevState, props) => {
                                    const newObs = prevState.observations.concat(resp);
                                    return {
                                        observations: newObs
                                  }
                              }
                               , () => {
                                    _this.parseObservations();
                                    _this.setMinAndMaxObservationValues();
                              }
                           );
            });
      } // loadStudyData()


      parseObservations(observations) {
            /* This method takes an array of observations and joins the observations by date/location so we have multiple
            * observations per record... */
            observations = observations || this.state.observations;

            const {locations} = this.state;
            let joinedObs = {};

            for (var i=0,l=observations.length; i<l; i++) {

                  let obs = observations[i]
                      , obsUnitId = obs.observationUnitDbId.toString()
                      , obsUnitName = obs.observationUnitName.toString();

                  if (!(obsUnitId in joinedObs)) {
                        let [study, block, plot] = obs.observationUnitName.split("-")
                              // We need to attach basic location data to the concatenated observations structure:
                              , _location = locations.filter(loc => study.startsWith(loc.name))
                              , city = null, state_or_province = null;

                        if (_location.length > 0) {
                              city = _location[0].additionalInfo.city;
                              state_or_province = _location[0].additionalInfo.state_or_province;
                        }

                        joinedObs[obsUnitId] = {
                              city: city,
                              state: state_or_province,
                              studyId: study,
                              block: block,
                              plot: plot,
                              observationLevel: obs.observationLevel,
                              germplasmName: obs.germplasmName,
                              observationUnitDbId: obsUnitId,
                              observationUnitName: obsUnitName,
                              observationTimestamp: obs.observationTimestamp
                        };
                  }

                  joinedObs[obsUnitId][obs.observationVariableDbId] = obs.value;

            } // for
            this.setState(
                  {recs: Object.values(joinedObs) }
                  , () => { this.chainFilters() }
            )

      } // parseObservations


      // Sorting functions
      sort(fieldName, direction) {
            // handle a couple of cases where the table header data doesn't match the keys in the data itself:
            if (fieldName.includes("(cms)")) fieldName = fieldName.replace(" (cms)", "").trim()
            if (fieldName.includes("(percentage)")) fieldName = fieldName.replace(" (percentage)", "").trim()


            let { recs } = this.state;
            const sortToggle = direction === 'asc' ? 1 : -1;

            function sorter(a,b) {
                /* Sort by numeric fields */
                const aVal = a[fieldName]
                    , bVal = b[fieldName];

                // Account for missing data:
                //if (isNaN(parseInt(aVal))) return -sortToggle;
                if (isNaN(aVal) || typeof aVal === "undefined" || aVal.length === 0) return -sortToggle;

                let retVal = (aVal - bVal) * sortToggle;

                if (isNaN(retVal)) {
                        if (aVal === bVal) {
                              retVal = 0
                        } else {
                              retVal = (aVal < bVal ? -1 : 1) * sortToggle;
                        }
                }
                return retVal;
            }

            if (fieldName.toLowerCase() === "date") {

                function sortByDate(a, b) {
                    try {

                        const d1 = new Date(a['observationTimestamp'])
                            , d2 = new Date(b['observationTimestamp']);


                        if (d1 === d2) return 0;

                        return ((d1 < d2) ? -1 : 1) * sortToggle


                    } catch (e) {
                        return 0;
                    }


                    return 0;
                }

                recs = recs.sort(sortByDate)

            } else {
                recs = recs.sort(sorter);
            }


            this.setState(
                  { recs: recs }
                  , this.chainFilters
            );
      }

      removeCurrentGermplasm(germplasm) {
            var {currentGermplasm} = this.state;

            currentGermplasm = currentGermplasm.filter(gp => gp !== germplasm);

            this.setState({
                  currentGermplasm: currentGermplasm
            });

      }

      render() {
            // Handler functions are prop-drilled to the child components:
            const handlers = {
                  clearStudies: this.clearStudies,
                  handleStudyClick: this.handleStudyClick,
                  handlePlantHeightChange: this.handlePlantHeightChange,
                  handleGrainMoistureChange: this.handleGrainMoistureChange,
                  handleAnthesisDapChange: this.handleAnthesisDapChange,
                  handleSilkingDapChange: this.handleSilkingDapChange,
                  handleGermplasmChange: this.handleGermplasmChange,
                  handleEarHeightChange: this.handleEarHeightChange,
                  handleSortClick: this.handleSort,
                  clearFilters: this.clearFilters,
                  removeCurrentGermplasm: this.removeCurrentGermplasm
            };

            const { messages, currentStudies, studies, allGermplasm, currentGermplasm, currentGermplasmStudies } = this.state
                , pendingCount = this.brapi.pendingRequestCount;

            return(
                  <div>
                        <ExplanationText />
                        <StudiesControl studies={studies} currentStudies={currentStudies} currentGermplasmStudies={currentGermplasmStudies} currentGermplasm={currentGermplasm} germplasm={allGermplasm} handlers={handlers} />
                        <ObservationRenderer handlers={handlers} {...this.state} />
                        <Messages isLoading={pendingCount !== 0} messages={messages} />
                  </div>
            )
      }
}

export default PlotObservations;