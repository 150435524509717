import React, { Component } from 'react';
import  NavBar from '../NavBar/NavBar';

export default class Header extends Component {

    render() {
        return <header>
            <NavBar />
            <hr />
        </header>
    }

    /*
    render() {
        return <nav>
            <ul>
                <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                <li><NavLink exact to="/observations" activeClassName="active">Home</NavLink></li>
                <li><NavLink exact to="/query" activeClassName="active">Query</NavLink></li>
            </ul>
            <hr />
        </nav>
    }
    */
}
