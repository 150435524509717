import React, { Component } from 'react';
import './Messages.css';

export default class Messages extends Component {


    render() {
        const {isLoading, messages} = this.props;
        let icon = '';

        if (isLoading) {

            icon = (<div>
                <div className="loading-icon">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>);
        }

        return <div id="messages">
            <div id="message_list_wrapper">
            {
                messages.reverse().map((msg, i) => <div key={i} dangerouslySetInnerHTML={{__html:msg}}></div>)
            }
            </div>
            { icon }
        </div>


    }

}